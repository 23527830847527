import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import styled from 'styled-components'
import imgSoon from "assets/images/glass.png"
import logo from "assets/images/logo.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalMission = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <div className="logo"></div>
                    <span className='size-2 text-center'>Mission</span>
                </div>
                <div className={`modal-body`}>
                    <span className="size-1">
                        Are you tired of meme coins leaving your investment portfolio feeling more like a joke than a jackpot? Fear not, for Meme & Exchange Commission ($MEC) is here to be your shield in the wild west of memecoin trading. Our mission? To keep the memes in check and protect crypto investors from ending up as the punchline. With $MEC on the beat, we're cracking down on meme coin tomfoolery and adding a dash of humor to the world of crypto regulation. So, buckle up and join us on this wild ride – where the memes are plentiful, the regulations are tight, and the laughter is never in short supply!
                    </span>
                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalMission

const Wrap = styled.div`
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    max-width: 468px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .logo {
            width: 105px;
            height: 105px;
            cursor: pointer;
            background-image: url(${logo});
            background-size: 100% 100%;
            margin-bottom: 10px;
        }
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: center;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    ${breakpointsMedias.max767} {
        .modal-body {

        }
    }
`