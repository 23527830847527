import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import styled from 'styled-components'
import imgSoon from "assets/images/glass.png"
import logo from "assets/images/logo.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalGame = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <div className="logo"></div>
                    <span className='size-2 text-center'>Game Hub</span>
                </div>
                <div className={`modal-body text-center`}>
                    <span className="size-1 text-center">
                        Introducing MEC Game Hub – your portal to a world of gaming excitement!
                    </span>
                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalGame

const Wrap = styled.div`
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    max-width: 468px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .logo {
            width: 105px;
            height: 105px;
            cursor: pointer;
            background-image: url(${logo});
            background-size: 100% 100%;
            margin-bottom: 10px;
        }
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: center;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    ${breakpointsMedias.max767} {
        .modal-body {

        }
    }
`