import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import styled from 'styled-components'
import imgSoon from "assets/images/glass.png"
import logo from "assets/images/logo.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalRoadmap = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();
    const data = [
        [
            "Connect with top tier KOLs and communities",
            "Complete the final stages of NFT collection, token launch"
        ],
        [
            "Launch token",
            "List Coingecko, Coinmarketcap",
            "Launch NFT collection",
            "Trending on Dextools, Dexscreener, all alpha tools"
        ],
        [
            "Launch NFT Staking",
            "Start uses 80% revenue from NFT collection buy back and burn to maintain deflation.",
            "CEXs"
        ],
        [
            "Launch Game"
        ]
    ]

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <div className="logo"></div>
                    <span className='size-2 text-center'>Road Map</span>
                </div>
                <div className={`modal-body`}>
                    {data.map((item, index) => <div className='mb-item' key={index}>
                        <span className="size-3">Phase {index + 1}</span>
                        <ul>
                            {item.map((item2, index2) => <li key={index2} className='size-1'>
                                - {item2}
                            </li>)}
                        </ul>
                    </div>)}

                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalRoadmap

const Wrap = styled.div`
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    max-width: 468px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .logo {
            width: 105px;
            height: 105px;
            cursor: pointer;
            background-image: url(${logo});
            background-size: 100% 100%;
            margin-bottom: 10px;
        }
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: left;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        margin-top: 14px;
        gap: 20px;
        .mb-item {
            gap: 10px;
            width: 100%;
            text-align: left;
            > ul {
                /* margin-left: 20px; */
                list-style:  none;
            }
        }
    }
    ${breakpointsMedias.max767} {
        .modal-title {
            text-align: center;
            > span {
                text-align: center;
            }
        }
        .modal-body {
            text-align: center;
            .mb-item {
                text-align: center;
            }
        }
    }
`