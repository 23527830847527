import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import logo from "assets/images/logo.png"

const Header = () => {
    const MENU_LIST = [
        {
            icon: <FaTelegramPlane color="black" size={37} />,
            link: "https://t.me/themectoken",
        },
        {
            icon: <FaXTwitter color="black" size={37} />,
            link: "https://x.com/themectoken",
        },
    ];

    return (
        <Wrap className='container'>
            <div className="logo"></div>
            {MENU_LIST.map((item, index) => <a href={item.link} key={index} target='_blank' rel='noreferrer' className='menu-item'>
                {item.icon}
            </a>)}
        </Wrap>
    )
}

export default Header

const Wrap = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;
    margin: 29px auto;
    gap: 30px;
    position: relative;
    z-index: 2;
    .logo {
        width: 64px;
        height: 64px;
        margin-right: auto;
        cursor: pointer;
        background-image: url(${logo});
        background-size: 100% 100%;
    }
    .menu-item {
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(287deg, #00FFA3 7.76%, #DC1FFF 98.51%);
        cursor: pointer;
    }
    ${breakpointsMedias.max1199} {

    }
    ${breakpointsMedias.max767} {
        .logo {
            width: 58px;
            height: 58px;
        }
        .menu-item {
            width: 50px;
            height: 50px;
            border-radius: 10px;
            > svg {
                width: 28px;
                height: 28px;
            }
        }
    }
`