import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import styled from 'styled-components'
import imgSoon from "assets/images/glass.png"
import logo from "assets/images/logo.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalStaking = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();
    const data = [
        "There will be 3 staking packages:",
        "Stake 1 NFT: APY 50%",
        "Stake 2 NFT: APY 150%",
        "Stake 3 NFT: APY 400%",
        "Coming soon"
    ]

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <div className="logo"></div>
                    <span className='size-2 text-center'>Staking</span>
                </div>
                <div className={`modal-body text-center`}>
                    {data.map((item, index) => <span key={index} className='size-1'>{item}</span>)}
                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalStaking

const Wrap = styled.div`
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    max-width: 468px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .logo {
            width: 105px;
            height: 105px;
            cursor: pointer;
            background-image: url(${logo});
            background-size: 100% 100%;
            margin-bottom: 10px;
        }
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: center;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
        width: 100%;
        /* gap: 20px; */
        > span:first-child {
            margin-bottom: 10px;
        }
        > span:last-child {
            margin-top: 10px;
        }
    }
    ${breakpointsMedias.max767} {
        .modal-body {

        }
    }
`