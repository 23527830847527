import styled from 'styled-components'
import { breakpointsMedias } from 'configs/breakpoints'
import { useEffect, useState } from 'react'
import bgHome from "assets/images/bg-meme.png"
import bgHome2 from "assets/images/bg-meme-2.png"
import item1 from "assets/images/page-coins.png"
import item2 from "assets/images/page-puzzle.png"
import item3 from "assets/images/page-planet.png"
import item4 from "assets/images/page-ribbon.png"
import item5 from "assets/images/page-trend.png"
import item6 from "assets/images/page-map.png"
import frameItem from "assets/images/page-icon.png"
import ModalGame from 'components/core/ModalGame'
import ModalMission from 'components/core/ModalMission'
import ModalChart from 'components/core/ModalChart'
import ModalRoadmap from 'components/core/ModalRoadmap'
import ModalNFT from 'components/core/ModalNFT'
import ModalStaking from 'components/core/ModalStaking'
import { useWidthScreen } from 'helpers/useScreen'


const Homepage = () => {
    const [modalShow, setModalShow] = useState(0);
    const { width } = useWidthScreen();

    const data = width > 768 ? [{
        icon: item1,
        top: 43,
        left: 17,
    }, {
        icon: item2,
        top: 48,
        left: 27,
    }, {
        icon: item3,
        top: 20,
        left: 34,
    }, {
        icon: item4,
        top: 10,
        left: 50,
    }, {
        icon: item5,
        top: 33,
        left: 72,
    }, {
        icon: item6,
        top: 14,
        left: 88,
    }] : [{
        icon: item1,
        top: 71,
        left: 41,
    }, {
        icon: item2,
        top: 74,
        left: 70,
    }, {
        icon: item3,
        top: 49,
        left: 82,
    }, {
        icon: item4,
        top: 12,
        left: 77,
    }, {
        icon: item5,
        top: 32,
        left: 20,
    }, {
        icon: item6,
        top: 18,
        left: 28,
    }]

    const onClose = () => {
        setModalShow(0)
    }

    useEffect(() => {
        const appear = document.querySelectorAll('.appear') as any;
        const appear2 = document.querySelector('.appear-right') as any;
        const appear3 = document.querySelector('.appear-left') as any;
        const cb = function (entries: any) {
            entries.forEach((entry: any) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('inview');
                }
                // else {
                //     entry.target.classList.remove('inview');
                // }
            });
        }
        const io = new IntersectionObserver(cb);
        for (let item of appear) {
            io.observe(item);
        }
        appear2 && io.observe(appear2);
        appear3 && io.observe(appear3);
    }, [])

    return (<Wrap>
        <div className="main">
            <img src={bgHome} alt="" className="" />
            {data.map((item, index) => <div key={index}
                className="page-item"
                style={{
                    top: `${item.top}%`,
                    left: `${item.left}%`
                }}
                onClick={() => { setModalShow(index + 1) }}
            >
                <div>
                    <div>
                        <img src={item.icon} alt="" />
                    </div>
                </div>
            </div>)}
        </div>
        {modalShow === 1 && <ModalStaking onClose={onClose} />}
        {modalShow === 2 && <ModalGame onClose={onClose} />}
        {modalShow === 3 && <ModalNFT onClose={onClose} />}
        {modalShow === 4 && <ModalMission onClose={onClose} />}
        {modalShow === 5 && <ModalChart onClose={onClose} />}
        {modalShow === 6 && <ModalRoadmap onClose={onClose} />}
    </Wrap>)
}

export default Homepage

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    background: #000;
    .main {
        margin-top: auto;
        width: 100%;
        display: flex;
        height: auto;
        position: relative;
        > img {
            width: 100%;
            height: auto;
        }
        .page-item {
            position: absolute;
            width: 1px;
            height: 1px;
       
            > div {
                position: relative;
                > div {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    animation: ss 5s infinite linear;
                    width: 5vw;
                    min-width: 50px;
                    height: 5.5vw;
                    min-height: 55px;
                    ${breakpointsMedias.max767} {
                        width: 16vw;
                        min-width: 63px;
                        height: 17.6vw;
                        min-height: 70px;
                    }
                    background-image: url(${frameItem});
                    background-size: 100% 100%;
                    background-position: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    > img {
                        width: 35.5%;
                        height: auto;
                        transform: translateY(-30%);
                    }
                }
            }
          
        }
    }
    @keyframes ss {
        0% {
            transform: translateY(-30%) translateX(-50%);
        }
        50% {
            transform: translateY(-70%)  translateX(-50%);
        }
        100% {
            transform: translateY(-30%) translateX(-50%);
        }
    }
    ${breakpointsMedias.max767} {
        /* overflow: hidden; */
        height: auto;
        .main {
            height: auto;
            > img {
                content: url(${bgHome2});
            }
        }
    }
`
