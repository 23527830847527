import Header from './components/header/Header';
import styled from 'styled-components';
import Homepage from 'pages/home/Homepage';
import { Suspense } from 'react';
import LoadingScreen from 'components/core/LoadingScreen';

function App() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Wrap>
        <div className='wrap-header'>
          <Header />
        </div>
        <Homepage />
      </Wrap>
    </Suspense>
  );
}

export default App;

const Wrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  overflow: auto;
  .wrap-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: fit-content;
  }
`