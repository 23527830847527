import ModalWrap from 'components/core/ModalWrap'
import { breakpointsMedias } from 'configs/breakpoints'
import styled from 'styled-components'
import imgChart1 from "assets/images/chart-1.png"
import imgChart2 from "assets/images/chart-2.png"
import imgChart3 from "assets/images/chart-3.png"
import logo from "assets/images/logo.png"

interface IModalTutorial {
    onClose: () => void,
}

const ModalChart = ({ onClose }: IModalTutorial) => {
    // useLockBodyScroll();
    const data = [
        {
            icon: imgChart1,
            link: "https://www.dextools.io/app/en/solana/pair-explorer/BkNR1XiVCEeSnR76WpmS5cq5iy1LHVFrAVu7ZbLWnZ7P"
        },
        {
            icon: imgChart2,
            link: "https://birdeye.so/token/DCDXZ29NhB9gwQDeN8A5MJiFudvq8BAEEJbYha79v2T2?chain=solana"
        },
        {
            icon: imgChart3,
            link: "https://dexscreener.com/solana/DCDXZ29NhB9gwQDeN8A5MJiFudvq8BAEEJbYha79v2T2"
        }
    ]

    return (
        <ModalWrap onClose={onClose} showCloseBt={true} clickOutsideToClose={true}>
            <Wrap className='mini-tutor'>
                <div className="modal-title">
                    <div className="logo"></div>
                    <span className='size-2 text-center'>Chart</span>
                </div>
                <div className={`modal-body text-center`}>
                    {data.map((item, index) => <a href={item.link} target='_blank' rel="noreferrer" key={index} className='size-1'>
                        <img src={item.icon} alt="" />
                    </a>)}
                </div>
            </Wrap>
        </ModalWrap>
    )
}

export default ModalChart

const Wrap = styled.div`
    padding: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100vw - 32px);
    max-width: 468px;
    cursor: default;
    .modal-title{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        .logo {
            width: 105px;
            height: 105px;
            cursor: pointer;
            background-image: url(${logo});
            background-size: 100% 100%;
            margin-bottom: 10px;
        }
        > span {
            width: 100%;
            /* max-width: 175px; */
            text-align: center;
        }
    }
    .modal-body {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: row;
        gap: 24px;
        justify-content: center;
        margin-top: 14px;
        > a {
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            background: #000;
            > img {
                width: 55px;
                height: auto;
            }
        }
    }
    ${breakpointsMedias.max767} {
        .modal-body {

        }
    }
`